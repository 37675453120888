<template>
  <main class="flex flex-col md:flex-row items-start">
    <div class="flex flex-row md:flex-col w-full md:w-52 md:items-start"></div>
    <div
      class="relative w-full text-sm h-auto lg:w-8/12 bg-white rounded-tr-none md:rounded-tr-lg rounded-tl-none rounded-lg border border-dark-50 shadow-md"
    >
      <!-- <span @click="reloadPage" class="absolute top-4 right-4 cursor-pointer">
        <img src="@/assets/icons/close.svg" alt="" />
      </span> -->
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, shallowRef } from "vue";

//// View Components ////
import EditBenefits from "@/components/Employee/Edit/Benefits/Benefits.vue";

const display = shallowRef(EditBenefits);

const pages = reactive([
  {
    title: "Job History",
    component: EditBenefits,
    active: true,
    live: "view",
  },
]);

// Function should toggle the Edit and Close button of the container, not done yet
// const toggleEditCloseButton = () => {
//   let live = "";
//   const activePage = pages.find((el) => el.active === true);
//   console.log(activePage);

//   if (activePage.live.toLocaleLowerCase() === "view") {
//     activePage.live = "edit";
//     live = activePage.live;
//   } else {
//     activePage.live = "view";
//     live = activePage.live;
//   }
// };
const reloadPage = () => {
  // store.$patch({
  //   pageIndex: currentPage,
  // });
  window.location.reload();
};

const switchView = (index) => {
  display.value = pages[index].component;
  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};
</script>

<style></style>

<!-- <template>
  <main>
    <ProfileComponent />
    <FamilyComponent />
    <HealthComponent />
    <CompanyInfo />
    <EducationHistory />
    <Certification />
    <Award />
    <JobHistory />
    <Document />
    <Asset />
    <Emergency />
    <Benefits />
    <Deduction />
  </main>
</template>

<script setup>
import ProfileComponent from "@/components/Employee/Edit/Personal/Profile.vue";
import FamilyComponent from "@/components/Employee/Edit/Personal/Family.vue";
import HealthComponent from "@/components/Employee/Edit/Personal/Health.vue";
import CompanyInfo from "@/components/Employee/Edit/Personal/CompanyInfo.vue";

//Education Components
import EducationHistory from "@/components/Employee/Edit/Education/EducationHistory.vue";
import Certification from "@/components/Employee/Edit/Education/Certification.vue";
import Award from "@/components/Employee/Edit/Education/Award.vue";

//Job Component
import JobHistory from "@/components/Employee/Edit/Job/JobHistory.vue";

//Document Component
import Document from "@/components/Employee/Edit/Documents/Documents.vue";

//Asset
import Asset from "@/components/Employee/Edit/Asset/Asset.vue";

//Emergency
import Emergency from "@/components/Employee/Edit/Emergency/Emergency.vue";

//Benefit
import Benefits from "@/components/Employee/Edit/Benefits/Benefits.vue";
import Deduction from "@/components/Employee/Edit/Benefits/Deduction.vue";
</script>

<style></style> -->
